@font-face {
  font-family: 'Optician Sans';
  src: url('../../assets/fonts/Optiker-K.woff2');
}

/* @font-face {
	font-family: 'Symbols';
	src: url('../../assets/fonts/Symbols.woff') format('woff'),
			 url('../../assets/fonts/Symbols.ttf') format('truetype'),
			 url('../../assets/fonts/Symbols.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
} */

@font-face {
  font-family: 'Symbols';
  src: url('../../assets/fonts/Symbols.eot');
  src: url('../../assets/fonts/Symbols.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/Symbols.woff') format('woff'),
       url('../../assets/fonts/Symbols.ttf') format('truetype'),
       url('../../assets/fonts/Symbols.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  max-width: 720px;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.document {
  width: 100%;
  max-width: 100vw;
  height: auto;
  font-size: 3vw;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  width: 500px;
}

.line {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0px solid #ddd;
  font-family: 'Optician Sans', sans-serif;
  text-align: center;
  border-bottom: 0px solid #333;
  margin: 20px 0;
}

.line1 { font-size: 1.953125em; }
.line2 { font-size: 1.5625em; }
.line3 { font-size: 1.25em; }
.line4 { font-size: 1.0em; }

.letter {
  height: 100%;
  display: block;
  line-height: .55;
  /* font-family: Arial, Helvetica, sans-serif; */
  margin: 0em .5em;
  padding: 0;
  /* background: red; */
  border: 0px solid red;
}

.symbol {
  font-family: 'Symbols';
  font-weight: normal;
  font-style: normal;
  font-size: 80%;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  color: black;
  -webkit-text-stroke-width: .0em;
  -webkit-text-stroke-color: black;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.letter-0 { transform: scale(1, 1); }
.letter-1 { transform: rotate(90deg) scale(1, 1); }
.letter-2 { transform: scale(-1, -1); }
.letter-3 { transform: rotate(90deg) scale(-1, -1); }

@page {
  size: A4 landscape;
  margin: 0;
}

@media print {
  @page {
    size: A4 landscape;
  }

  html, body {
    /* width: 210mm;
    height: 297mm; */
    font-size: 25mm;
  }
  
  header, footer, body * {
    visibility: hidden !important;
  }

  .wrapper > div:not(.document)  {
    display: none;
  }
  
  .wrapper {
    width: 100%;
    margin: 0;
    overflow: hidden;
    position: static;
  }

  .not-printable {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .document {
    height: 100%;
    font-size: 25mm; /* normal*4 */
    box-sizing: border-box;
    border: 0px solid #ddd;
    position: absolute;
    font-family: 'Optician Sans';
    top: 0;
    left: 0;
  }

  .document, .document * {
    visibility: visible !important;
  }
  
  .container {
    width: 230mm;
  }

  .line {
    margin: .8rem 0;
    transform: scale(-1, 1);
  }

  /* .letter {
    
  } */
}
